import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";

const Tooltip = ({ delay, duration, imgSrc, alt, blackOverlay }) => {
  var [present, setPresent] = useState(false);
  var [init, setInit] = useState(false);
  if (!init) {
    setTimeout(() => {
      setPresent(true);
      setTimeout(() => {
        setPresent(false);
      }, duration * 1000);
    }, delay * 1000);

    setInit(true);

  }

  const backgroundOpacity = blackOverlay ? 0.75 : 0

  return (
    <AnimatePresence>
      {present && (<motion.div
              initial={{ background: "rgba(0, 0, 0, 0)", }}
              animate={{ background: "rgba(0, 0, 0, " + backgroundOpacity + ")", }}
              exit={{ background: "rgba(0, 0, 0, 0)" }}

              style={{
                background: "rgba(0, 0, 0, " + backgroundOpacity + ")",
                width: "100%",
                height: "100%",
                position: "absolute",
                left: 0,
                right: 0,
                top: 0,
                zIndex: 50,
                userSelect: "none",
                userDrag: "none",
              }}
          >
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          style={{
            position: "absolute",
            top: "40%",
            left: "50%"
          }}
        >
          <img
            src={imgSrc}
            alt={alt}
            draggable={false}
            style={{ width: "35vh", position: "relative", left: "-50%" }}
          />
        </motion.div>
          </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Tooltip;
