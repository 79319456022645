import React, { useState, useEffect } from "react";

const ShortLived = ({onFinished, children}) => {
    var [finished, setFinished] = useState(false)

    useEffect(() => {
        setFinished(false)
        setTimeout(() => {
            setFinished(true)
            onFinished()
        }, 750)
    }, [onFinished])

    return ( <>
        {
            !finished && children
        }

    </> );
}
 
export default ShortLived;
