import React, { useState, useRef } from "react";
import ARExperience from "./ARExperience";
import FauxModal from "./FauxModal";
import { Button, Image, Dimmer, Loader } from "semantic-ui-react";
import { motion, AnimatePresence } from "framer-motion";
import { pf } from "./config";

const SkipCutscene = false;

const styles = {
  background: {
    width: "100vw",
    height: "100vh",
    backgroundColor: "black",
    backgroundImage: 'url("' + pf + 'media/landing_background.jpg")',
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    fontFamily: "'program', sans-serif"
  },
  btnCorner: {
    background: "#fce300",
    color: "#885138",
    fontFamily: "'program', sans-serif",
    display: "block",
    padding: 10,
    position: "absolute",
    top: 10,
    right: 10
  },
  btnMain: {
    //background: "#151f6d",
    //color: "#fce300",
    //textShadow: "1px 1px 0 #000",
    background: "#fce300",
    color: "#885138",
    fontFamily: "'program', sans-serif"
  },
  clickText: {
    color: "white"
  }
};

function App() {
  const videoRef = useRef(null);
  const [started, setStarted] = useState(SkipCutscene);
  const [finished, setFinished] = useState(SkipCutscene);
  const [buffering, setBuffering] = useState(false);

  return (
    <AnimatePresence
      style={{ position: "fixed", top: 0, left: 0, bottom: 0, right: 0 }}
    >
      {!finished && (
        <motion.div
          initial={{ opacity: 1, scale: 1 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 1.2 }}
          onClick={() => {
            videoRef.current.play();
            setStarted(true);
          }}
          style={styles.background}
        >
          <video
            playsInline
            preload="auto"
            ref={videoRef}
            onEnded={() => setFinished(true)}
            onWaiting={() => setBuffering(true)}
            onPlaying={() => setBuffering(false)}
            style={{
              pointerEvents: "none",
              objectFit: "contain",
              width: "100%",
              height: "100%"
            }}
          >
            <source type="video/mp4" src={pf + "media/trailer_30_sec.mp4"} />
          </video>
          <Dimmer active={buffering}>
            <Loader />
          </Dimmer>
          <FauxModal open={!started}>
            <Image className={"disable-select"} src={pf + "media/logo.png"} />
            <Button
              style={{ marginTop: "25px", ...styles.btnMain }}
              size={"large"}
              className={"disable-select faux-clickable"}
            >
              Play trailer and unlock photo filter
            </Button>
            <br />
          </FauxModal>
          {started && (
            <Button
              style={styles.btnCorner}
              onClick={() => {
                setFinished(true);
              }}
              content={"See yourself as SpongeBob SquarePants"}
              icon={"close"}
              labelPosition={"right"}
            />
          )}
        </motion.div>
      )}
      {finished && <ARExperience />}
    </AnimatePresence>
  );
}

export default App;
