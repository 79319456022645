import React, { useRef, useState, useLayoutEffect } from "react";
import Tooltip from "./Tooltip";
import ShortLived from "./ShortLived";

import { pf } from "./config";

import jimp from "jimp/es";
import { saveAs } from "file-saver";
import { Loader, Dimmer } from "semantic-ui-react";
import { motion } from "framer-motion";
import nanoid from "nanoid";

var deepAR;

const ARExperience = () => {
  const deepARCanvas = useRef(null);

  const [size, setSize] = useState({
    h: window.innerHeight,
    w: window.innerWidth
  });

  window.onresize = () =>
    setSize({
      h: window.innerHeight,
      w: window.innerWidth
    });

  const [loaded, setLoaded] = useState(false);

  function setupDeepAR() {
    const isPortrait = size.h > size.w;
    const height = size.h;
    const width = isPortrait ? size.w : 0.5625 * size.h;

    // eslint-disable-next-line no-undef
    deepAR = DeepAR({
      canvasWidth: width,
      canvasHeight: height,
      licenseKey:
        "12f68336ed999f743aeb73fdb2abdba40ea3d4b68cee8f78f579bb24a7c7c4962941ff01817f1c15",
      canvas: deepARCanvas.current,
      numberOfFaces: 4,
      onInitialize: function() {
        deepAR.startVideo(true);
        deepAR.switchEffect(
          0,
          "slot",
          pf + "effects/spongebobMaskV9",
          function() {}
        );
      }
    });

    deepAR.downloadFaceTrackingModel(pf + "models/models-68-extreme.bin");

    deepAR.onVideoStarted = () => setLoaded(true);

    deepAR.onScreenshotTaken = async function(photo) {
      console.log("screenshot taken");

      const buffer = Buffer.from(
        photo.replace(/^data:image\/png;base64,/, ""),
        "base64"
      );

      jimp.read(buffer, (err, smile) => {
        if (err) {
          console.log(err);
          return;
        }
        jimp.read(pf + "media/overlay.png", (err, overlay) => {
          if (err) {
            console.log(err);
            return;
          }
          const target = {
            x: 0,
            y:
              deepARCanvas.current.height -
              overlay.getHeight() *
                (deepARCanvas.current.width / overlay.getWidth()),
            w: smile.getWidth(),
            h:
              overlay.getHeight() *
              (deepARCanvas.current.width / overlay.getWidth())
          };

          overlay.resize(target.w, target.h);
          smile.blit(overlay, target.x, target.y);
          smile.getBase64(jimp.MIME_JPEG, (err, data) =>
            fetch(data)
              .then(res => res.blob())
              .then(res => saveAs(res, "SpongeSelfie-" + nanoid(8) + ".jpg"))
              .catch(err => console.log(err))
          );
        });
      });
    };
  }

  useLayoutEffect(() => {
    var height = size.h;
    var width = size.h > size.w ? size.w : 0.5625 * size.h;

    if (loaded) deepAR.setCanvasSize(width, height);
  }, [loaded, size]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(() => setupDeepAR(), []);

  const [shutter, setShutter] = useState(false);

  var height = size.h;
  var width = size.h > size.w ? size.w : 0.5625 * size.h;

  return (
    <motion.div
      initial={{
        opacity: 1,
        scale: 1.2
      }}
      animate={{
        opacity: 1,
        scale: 1.0
      }}
      exit={{}}
      transition={{ ease: "easeInOut" }}
      style={{
        background: "black",
        height: "100vh",
        width: "100vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "start",
        flexDirection: "column",
        overflow: "hidden"
      }}
    >
      <div
        className={"capture"}
        style={{
          height: { height },
          width: { width },
          position: "relative"
        }}
      >
        <canvas
          height={height}
          width={width}
          id="deepar-canvas"
          ref={deepARCanvas}
          onContextMenu={ev => ev.preventDefault()}
        />
        <div
          id={"overlay"}
          style={{
            position: "absolute",
            backgroundImage: 'url("' + pf + 'media/overlay.png")',
            backgroundPosition: "bottom left",
            backgroundSize: "100% auto",
            backgroundRepeat: "no-repeat",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0
          }}
        />
        {loaded && (
          <>
            <Tooltip
              delay={3}
              duration={5}
              imgSrc={pf + "media/hint_open_mouth.png"}
              alt={"Open Mouth!"}
            />
            <Tooltip
              delay={13}
              duration={5}
              imgSrc={pf + "media/hint_sweepstakes.png"}
              alt={"Enter Sweepstakes!"}
              blackOverlay={true}
            />
          </>
        )}

        {shutter && (
          <ShortLived
            onFinished={() => {
              setShutter(false);
            }}
          >
            <Dimmer style={{ zIndex: 60 }} active page />
          </ShortLived>
        )}

        <Dimmer active={!loaded}>
          <Loader>
            <p>Loading! Just a moment.</p>
            <p>Not loading? Make sure that you allow camera access.</p>
          </Loader>
        </Dimmer>
        <motion.div
          style={{
            height: "16vh",
            width: "16vh",
            backgroundSize: "cover",
            margin: "auto",
            right: 0,
            left: 0,
            bottom: "5vh",
            position: "absolute",
            backgroundImage: 'url(' + pf + 'media/capture_btn.png)',
            zIndex: shutter ? 75 : "auto",
          }}
          whileTap={{ scale: 0.8 }}
          onClick={() => {
            // capture logic
            console.log("CAPTURE! ");
            setShutter(true);
            deepAR.takeScreenshot();
          }}
        />

        <img
          src={pf + "media/sweepstake_btn.png"}
          alt="Enter Sweepstakes"
          style={{
            height: "14vh",
            position: "absolute",
            bottom: "6vh",
            left: 10,
            zIndex: 51,
            userSelect: "none",
            userDrag: "none"
          }}
          draggable={false}
          onClick={() => {
            window.open("https://www.wheresgarygetaway.com/", "_blank");
          }}
        />
      </div>
    </motion.div>
  );
};

export default ARExperience;
