import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { pf } from "./config";

const FauxModal = ({ open, children }) => {
  return (
    <AnimatePresence initial={false}>
      {open && (
        <motion.div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            background: "black",
            height: "100vh",
            width: "100vw",
            color: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            backgroundImage: 'url("' + pf + 'media/landing_background.jpg")',
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover"
          }}
          animate={{
            opacity: 1,
            scale: 1
          }}
          exit={{
            opacity: 0,
            scale: 1.2
          }}
        >
          <AnimatePresence>
            <motion.div
              style={{ textAlign: "center" }}
              initial={{ opacity: 0, scale: 1.2 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{}}
            >
              {children}
            </motion.div>
          </AnimatePresence>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default FauxModal;
